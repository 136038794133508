<template>
  <div>
    <main>
      <div class="container customer-container">
        
        <v-card class="py-8 px-6 text-center mx-auto ma-4" elevation="12" max-width="400" width="100%">
          <img src="../../public/harlequin.svg" alt="">
          <h3 class="text-h6 text-uppercase">Emergency Notification Service</h3>
          <h3 class="text-h6 mb-4 text-center text-uppercase">Sign In</h3>
          
          <v-sheet color="surface">
            <v-form
            v-model="form"
            
          >
            <v-text-field label="Email" variant="outlined" v-model="formData.email" type="email" required  :rules="emailRules"></v-text-field>
            <v-text-field label="Password" variant="outlined" v-model="formData.password" type="password" required :rules="[rules.required]"></v-text-field>
            </v-form>
          </v-sheet>
          
          <v-btn class="my-4" color="#e83e8c" height="40" text="Sign In" variant="flat" width="70%"
             :disabled="!form" type="submit" @click.prevent="SignIn"></v-btn>
             <div class="text-caption">
                         <router-link to='/forgot-password'>Forgot Password?</router-link>
                    </div>
        </v-card>

      </div>

    </main>
  </div>
</template>
<script setup>
import { ref, inject } from 'vue'
import { makePostRequest } from '@/request';
import { useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';
import { useUserStore } from '@/stores'
import is401 from '@/utils/is401'

const swal = inject('$swal')
const router = useRouter()
const formData = ref({
  email: '',
  password: ''
})
const form = ref(false)
const rules = ref({
        required: value => !!value || 'Field is required',
})
const emailRules = ref([
             (v) => !!v || 'E-mail is required',
             (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(v) || 'E-mail must be valid'
        ],
)
const clearForm = () => {
  formData.value.email = ''
  formData.value.password = ''
}
const preLoader = () => { 
      swal.fire({title: "", 
      customClass: {
          htmlContainer: 'custom-swal-popup',
      },
      html: '<div style="height:auto;width:50%;display:flex;align-items:center !important;justify-content:center !important"><img style="width: 100%;text-align:center" src="loader.gif" /></div>', showConfirmButton: false, showCancelButton: false, allowOutsideClick: false});
}
const SignIn = () => {
  if (!form.value) return
  preLoader()
  const user = useUserStore()
  var request = {
    what: "UserAuthentication",
    data: formData.value
  };
  makePostRequest(request)
    .then(res => {
      if (res.status == true) {
        user.setEmail(formData.value.email)
        user.setPassword(formData.value.password)
        clearForm()
        swal.close()
        router.push('/verify')
      }
      else {
        toast.success(res.msg);
      }
    })
    .catch(error => {
      console.log(error, 'error')
      swal.close()
      toast.error(error)
    });

}
</script>
<script>

export default {
  name: 'UserLogin',
}
</script>
<style scoped>


</style>