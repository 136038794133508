<template>
  <div class="bread-crumb__container shadow pa-3">
      <i class="pointers" title="Click to Go to Alert List" @click="$router.push('/report')"><font-awesome-icon icon="fa-solid fa-arrow-left" /></i>
      <p class="text-capitalize">Location</p>
    </div>
  <div id="map" style="width: auto; height: 100vh"></div>
</template>
<script setup>
import { useDocument  } from 'vuefire'
import { collection, getFirestore, doc, onSnapshot} from 'firebase/firestore'
import {ref, onMounted, watch, watchEffect} from 'vue'
import {fb} from '../firebase/init'
import { useGeolocation } from '@vueuse/core'
import { useRoute } from 'vue-router'
import { toast } from 'vue3-toastify';

const pos = ref('')
const route = useRoute()
const { coords } = useGeolocation()
const db = getFirestore(fb)
const some_loc = ref(null)
// useDocument(doc(collection(db, 'user_locations'), route.params.id))
const center =  ref('')
const directionsService = ref(null)
const directionsRenderer = ref(null)
const map = ref(null)

const options = {
  enableHighAccuracy: true,
  maximumAge: 30000,
  timeout: 27000,
};
const fetchLocation = async () => {
  const documentRef = doc(collection(db, 'user_locations'), route.params.id);
  const unsubscribe = onSnapshot(documentRef, (snapshot) => {
    if (snapshot.exists()) {
      some_loc.value = snapshot.data();
    } else {
      some_loc.value = null; // Reset some_loc if document doesn't exist
    }
  });

};

watch([coords, some_loc], ([newCoords, location]) => {
  if (!newCoords || !location) return; // Skip if data is not yet available

  pos.value = { lat: location.latitude, lng: location.longitude } 
  
  center.value = { lat: newCoords.latitude, lng: newCoords.longitude }
});

const initMap = () => {
  directionsService.value = new window.google.maps.DirectionsService()
  directionsRenderer.value = new window.google.maps.DirectionsRenderer({
    polylineOptions:{
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 5,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
        }
  })

 const mapElement = document.getElementById('map')
  map.value = new window.google.maps.Map(mapElement, {
    zoom: 7,
    center: center.value,
  })

  directionsRenderer.value.setMap(map.value)
  

 
  calculateAndDisplayRoute(directionsService.value, directionsRenderer.value)
  watchEffect(() => {
    calculateAndDisplayRoute(directionsService.value, directionsRenderer.value)
  });
  
}
const calculateAndDisplayRoute = (service, renderer) => {
  const start = new window.google.maps.LatLng(some_loc?.value?.latitude, some_loc?.value?.longitude);
  service
    .route({
      origin: start,
      destination: center.value,
      travelMode: 'DRIVING',
    })
    .then((response) => {
      renderer.setDirections(response)
    })
    .catch((error) => {
      console.error('Directions request failed:', error)
    })
}
const success = (position) => {
  center.value = { lat: position.coords.latitude, lng: position.coords.longitude };
}

const error = () => {
  toast.error("Sorry, no position available.");
}

const location_options = {
  enableHighAccuracy: true,
  maximumAge: 30000,
  timeout: 27000,
};
onMounted(() => {
  setTimeout(() => {
    const script = document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCWugqPI9pOfAENPd4B-DmApRfBtNkDWbo&&callback=initMap&libraries=places`;
  script.defer = true;
  document.head.appendChild(script);
    }, 1000);
  const isSupported = 'navigator' in window && 'geolocation' in navigator
  if (isSupported) {
  navigator.geolocation.getCurrentPosition(
    success, error, location_options
  );
  }
  fetchLocation()
})
window.initMap = initMap;
</script>
<script>
export default {
  name: 'MapLayout',
 
}
</script>